<template>
  <v-container>
    <h1 class="ma-4 d-flex justify-center">
      <v-img
        src="@/assets/ubpfLogo.png"
        contain
        max-width="300"
        class="ma-4 d-flex justify-center"
      ></v-img>
    </h1>
    <v-layout class="ma-3" name="layoutIntro">
      <v-flex xs12 class="ma-2">
        <v-alert outlined color="#ebb63b">
          <v-card flat pa-2>
            <p class="ahcGreen" style="white-space: pre-wrap">
              {{ ubpfIntro }}
            </p>
          </v-card>
        </v-alert>
      </v-flex>
    </v-layout>
    <!-- <v-layout row wrap class="ma-3" name="layoutModulesUbpf">
      <v-flex xs12 class="ma-2" name="beer selector">
        <v-card>
          <v-tabs
            align-with-title
            centered
            background-color="#ebb63b"
            text-color="#cc212f"
            active-class="ahcRed"
            v-model="tab"
            show-arrows
          >
            <v-tab v-for="(brewery, index) in shuffledBreweries" :key="index">
              {{ brewery.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(brewery, index) in shuffledBreweries"
              :key="index"
            >
              <v-card class="ma-1" flat>
                <v-card-title class="ahcRed">{{ brewery.name }}</v-card-title>
                <v-card-subtitle class="ahcGreen">{{
                  brewery.website
                }}</v-card-subtitle>
                <p class="mx-5 ahcGreen">{{ brewery.intro }}</p>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="(beer, index) in brewery.beers"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="ahcGreen">
                          <b>{{ beer.name }}</b></v-list-item-title
                        >
                        <v-list-item-subtitle
                          ><i
                            >{{ beer.alcoholPercentage }} -
                            {{ beer.IBU }} IBU</i
                          ></v-list-item-subtitle
                        >
                        <p>{{ beer.intro }}</p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap class="ma-3">
      <v-flex xs12 class="ma-2" name="flexName">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b"> <span class="ahcRed subheading">
                <b>Inschrijven voor de lezing op zondag 19 mei - 13u</b>
              </span></v-system-bar>
            <v-form class="ma-5">
              <div class="text-center mb-2">
                <span class="center green--text" v-if="feedback">{{
                  feedback
                }}</span>
              </div>
              <v-text-field
                v-model="ubpfInschrijving.name"
                label="Wat is je naam?"
              >
              </v-text-field>
              <v-text-field
                v-model="ubpfInschrijving.email"
                label="Wat is je e-mail? (optioneel)"
              >
              </v-text-field>
              <v-btn
                class="ma-3"
                v-if="ubpfInschrijving.name"
                @click="sendInschrijving()"
                >Aanmelding versturen</v-btn
              >
            </v-form>
          </v-card>
        </v-flex>
    </v-layout> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: 0,
      tab: null,
      ubpfInschrijving: {},
      feedback: null,
    };
  },
  computed: {
    newsItems() {
      return this.$store.state.ubpfNewsItems;
    },
    ubpfIntro() {
      return this.$store.state.intros.ubpf;
    },
    newsImages() {
      return this.$store.state.newsImages;
    },
    shuffledBreweries() {
      // Shuffle the breweries using a Fisher-Yates shuffle algorithm
      let shuffled = [...this.$store.state.breweries];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    },
  },
  methods: {
    sendInschrijving() {
      this.feedback = null;
      this.$store.dispatch("ubpfInschrijving", {
        inschrijving: this.ubpfInschrijving,
      });
      this.ubpfInschrijving = {};
      this.feedback = "Bedankt voor je inschrijving, tot zondag 19 mei aanstaande!";
    },
  },
  mounted() {
    this.$store.dispatch("getBreweries");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
.v-label {
  color: #305645;
}
</style>